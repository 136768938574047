define("discourse/plugins/auto-insert-wiki/discourse/initializers/auto-insert-wiki", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner", "@ember/runloop", "@ember/template"], function (_exports, _pluginApi, _ajax, _ajaxError, _getOwner, _runloop, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    api.decorateCooked(cooked => {
      (0, _runloop.next)(cooked, function () {
        const el = this.find("strong:contains(wiki_edit_btn)");
        const topic = $("#topic");
        if (el.length && topic.length) {
          const parent = el.parent("p");
          const btn = $(`<button class="btn btn-large btn-primary">Add Summary</button>`);
          const topicId = topic.data("topic-id");
          btn.click(() => {
            if ($(this).hasClass("disabled")) return;
            const dialogMessage = "You are about to add a blank Summary template to this topic. Please do this only if you will contribute to the summary. Are you sure?";
            (0, _runloop.schedule)("afterRender", () => {
              // delay needed so that the dialog service is loaded
              const dialog = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:dialog");
              dialog.yesNoConfirm({
                message: (0, _template.htmlSafe)(dialogMessage),
                didConfirm: () => {
                  $(this).addClass("disabled");
                  (0, _ajax.ajax)("/auto-insert-wiki", {
                    type: "POST",
                    data: {
                      id: topicId
                    }
                  }).then(result => {
                    //console.log(result);
                  }).catch(_ajaxError.popupAjaxError).finally(() => {
                    $(this).removeClass("disabled");
                  });
                }
              });
            });
          });
          parent.html(btn);
        }
      });
    }, {
      onlyStream: true
    });
  }
  var _default = _exports.default = {
    name: "auto-insert-wiki",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initWithApi);
    }
  };
});